<template>
  <div v-bind:style="styles">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex"   >
       
       <div class="backbox" style="margin-left: 3px;">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Student Attendnce

       </h4>
      </div>
      <div class="row mt-2 ml-2 mr-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div>
              <div
                class="dispflex mt-3 pt-3" style="padding-left: 1%;"
                
              >
                <div class="dk_icon">
                
                  <!-- <span>View Student Leave Request </span> -->
                </div>
                <div class="dk_icon" style="width: 12%;">
              <span class="has-float-label" style="margin-top: 3%;">
                <select
                  class="form-control form-input"
                  id="typeList"
                  v-model="selectedMonth"
                  @change="getStudentAttendanceList"
                >
                  <option
                    v-for="(item, index) in previousMonths"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
                <label for="clslistid">Select Month </label>
              </span>
            </div>
                <div class="text-right mr-1">
                 
                </div>
              </div>
              
            </div>
            <div class="p-3 widjetcontent">
              <div class="widjetcontent mt-1 tbllist">
                    <div>
                      <div class="crtfeeplancont">
                       
                        <div class="restable">
                          <div class="resrow resheader">
                              
                              <div class="cell">Date</div>
                              <div class="cell">Status</div>
                              <div class="cell">Leave</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(item, index) in attendanceList"
                            :key="index"
                          >
                         
                          <div class="cell">{{ item.attendanceDate | dateFormat }}</div>
                         
                          <div class="cell"  :style="
                                    item.status == 'Present'
                                      ? 'color: lawngreen;'
                                      : 'color: red;'
                                  ">{{ item.status == 'Present' ? 'Present' : item.status }}</div>
                           <div class="cell">{{  item.status == 'Leave' ? 'Yes' : '-'}}</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
    
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import order from "../Common/order";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      viLoader: false,
      selectedMonth: null,
      attendanceList: [],
      previousMonths: []
    };
  },
  created() {
  //  this.getStudentLList();
    this.getPreviousMonths()
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    },
   
  },

  methods: {
    previousRoute(){
      this.$router.push(`/parent/student/home/${this.$route.params.id}`);
  //    let data = localStorage.getItem('previousTab');
  //    localStorage.setItem("activeTab",data);
  //    localStorage.setItem("previousTab",this.$route.path);
  //  //  this.$router.go(-1)
  //     this.$router.push({ path: data });
    },
    getPreviousMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

     
      for (let i = currentMonth; i >= 0; i--) {
        this.previousMonths.push({
          label: months[i],
          value: i,
        });
      }
      this.selectedMonth = this.previousMonths[0].value,
      this.getStudentAttendanceList()
    },
    async getStudentAttendanceList() {
  //  this.viLoader = true
    let userData = secureUI.sessionGet("user");

    if (!userData) {
      this.$toasted.error("Please login and do the action");
    } else {
      const response = await ViService.viXGet(
        `/contact/getStudentAttendanceList/${this.$route.params.id}?month=${this.selectedMonth}`,
        userData.token
      );
      if (response.isSuccess) {
        var result = secureUI.secureGet(response.data);
        this.attendanceList = result
      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    }
    this.viLoader = false
  },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    }
  },
  components: {
    "v-select": Select,
    ViSpinner
  },
};
</script>
<style scoped>
ul,
ul li,
p,
div,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoice {
  margin: 0 auto;
  width: 999px;

  clear: both;
  position: relative;
  background: #fff;
}
.feedate {
  font-size: 0.78rem;
  text-align: right;
  margin: 0 auto;
  padding: 10px 0;
  width: 999px;
}
table,
td,
th {
  border: 1px solid black;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* table{
         border-collapse:collapse;
         width:100%;
        clear:both;
      } */
</style>